@import '_msl-product-view-variables';

.product-view {

    &__add-to-cart,
    &__update-cart {

        background-color: $product-view__add-to-cart-background-color;

    }

    &__add-to-cart::before,
    &__update-cart::before {

        background-color: $product-view__add-to-cart-background-color;

    }


    &__add-to-quote,
    &__update-quote {
        display: $product-view__add-to-quote;
        order: $product-view__order;
        flex-grow: $product-view__flex-grow;
        height: $product-view__add-to-quote-height;
        padding: $product-view__add-to-quote-padding;
        background-color: $product-view__add-to-quote-background-color;
        @include mq($product-view__buttons-mq-display) {
            display: $product-view__add-to-quote\@buttons-mq-display;
        }
    }

    &__add-to-quote::before,
    &__update-quote::before {
        display: $product-view__add-to-quote;
        order: $product-view__order;
        flex-grow: $product-view__flex-grow;
        height: $product-view__add-to-quote-height;
        padding: $product-view__add-to-quote-padding;
        background-color: $product-view__add-to-quote-background-color;
        @include mq($product-view__buttons-mq-display) {
            display: $product-view__add-to-quote\@buttons-mq-display;
        }
    }

}

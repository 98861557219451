// Variables
@import '../Snowdog_Components/components/Atoms/variables/variables';
@import '../Snowdog_Components/components/Atoms/variables/msl-variables';

// Components
@import '../Snowdog_Components/components/styles/non-critical';

@import '../Snowdog_Components/components/_msl-styles';

// Theme partials
@import '../Amasty_Storelocator/styles/module';
@import '../Amasty_GiftCard/styles/module';
@import '../Magento_Ui/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_Customer/styles/module';
@import '../VladimirPopov_WebForms/styles/module';
@import '../Magento_PageBuilder/styles/module';
@import '../Magento_QuickOrder/styles/module';
@import '../Yotpo_Yotpo/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../PayPal_Braintree/styles/module';
@import '../Magento_ReCaptchaFrontendUi/styles/module';
@import '../Magento_Theme/styles/module-non-critical';

// Show sections hidden in critical.scss
.page-main,
.footer {
    display: block !important;
}


.contact-bar {
  display: flex;
}

.contact-bar__list--icons .contact-bar__link {
    padding-left: 0px;
}

.list__item:last-child {
    padding-bottom: 0;
    padding-left: unset;
    padding-right: unset;
    padding-top: unset;
}

.pagebuilder-slider a{
  display:block;
  width: 100%;
}

.slick-dots li button:active, .slick-dots li button:focus, .slick-dots li button:not(.primary) {
    box-shadow: none;
    border: 1px black solid;
}

.catalog-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $catalog-grid__gap;
    margin: $catalog-grid__margin;
    padding: $catalog-grid__padding;
    list-style: none;


    @include mq($screen-s) {
        grid-template-columns: 1fr 1fr;
    }

    @include mq($screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include mq($screen-l) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include isIE() {
        display: flex;
        flex-wrap: wrap;
    }
}

.quotation-quote-index .form-cart {
    float:left;
}

.shopbrand-brand-listbrand #maincontent {
  margin-top: 0px;
}

.shop-by-brand {
    text-align: center;
}


  .block-search {
      position: relative;
      margin-left: 40px;
      width: 450px;
      z-index: 4;
      display: inline-block;
      vertical-align: middle;
  }

.block-search input {
    left: -300%;
    margin: 0;
    border-color: #0d0d0d;
    height: 40px;
    border-radius: 20px;
    padding: 10px 50px 10px 20px;
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 1em;
    height: 38px;
    line-height: 1.4;
    padding: 10px;
    vertical-align: baseline;
    width: 100%;
    box-sizing: border-box;
}

.block-search .action.search {
    right: 14px;
}

.block-search .action.search {
    position: absolute;
    right: 10px;
    top: 0;
    z-index: 1;
    height: 40px;
    display: inline-block;
    background-image: none;
    background: 0 0;
    -moz-box-sizing: content-box;
    border: 0;
    box-shadow: none;
    line-height: inherit;
    margin: 0;
    padding: 0;
    text-decoration: none;
    text-shadow: none;
    font-weight: 400;
    line-height: 40px;
}

.block-search .action.search:before {
    margin: 0;
    vertical-align: top;
    display: inline-block;
    font-weight: 400;
    overflow: hidden;
    speak: none;
    text-align: center;
}


@media screen and (max-width: 767px) {

.block-search {
  margin-left: 0px;
  width: 350px;
}

.characters .filter {
    padding-inline-start: 0px;
}

}

.block-search .label, .block-collapsible-nav .title {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.block-search .label {
    text-decoration: none;
    display: inline-block;
    float: right;
}

.block-search .label>span {
    border: 0;
    clip: rect(0,0,0,0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.characters {
    padding-top: 60px;
    padding-bottom: 40px;
    padding-left: 10px;
    justify-content: center;
    display: flex;
    clear: both;
}

.characters .filter {
    list-style: none;
    float: left;
    padding-inline-start: 0px;
}

.characters .filter .char {
    float: left;
}

.characters .filter .char a {
    display: block;
    border: 1px solid silver;
    height: 36px;
    line-height: 36px;
    width: 36px;
    text-align: center;
    margin-left: 3px;
    border-radius: 4px;
    font-weight: 600;
    text-transform: uppercase;
}
//Snowdog_Components/components/Organisms/product-grid-item/_product-grid-item.scss

.product-grid-item {

    &__price {

        @include mq($screen-m) {
            width: 100%;
        }

    }

    &__actions {

        @include mq($screen-m) {
            width: 100%;
        }

    }

}

.product-grid-item__price .affirm-as-low-as {
    white-space: normal;
    font-size: 12px;
}
#ratings_summaryFilter {
max-height: 215px;
}

#ratings_summaryFilter ol{
margin: 0px;
padding: 0px;
}

#ratings_summaryFilter ol li{
list-style-type: none;
}

#ratings_summaryFilter .link,#ratings_summaryFilter a{
  line-height: 1.9;
}

#ratings_summaryFilter .count{
  padding-left: 5px;
}
#ratings_summaryFilter .count:before {
  content: '(';
}

#ratings_summaryFilter .count:after {
  content: ')';
}

.rating-result{
position: relative;
width: 120px;
}

.rating-result:before{
  content: '';
  display: inline-block;
  width: 120px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath transform='translate(2 2)' d='M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19z' fill='%23d8d8d8'/%3E%3C/svg%3E");
  background-repeat: repeat-x;
  background-position: left center;
  background-size: 24px;
}

.rating-result span{
  position: absolute;
  display: inline-flex;
  left: 0;
}

.rating-result span:before{
    content: '';
    display: inline-block;
    width: 120px;
    height: 24px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath transform='translate(2 2)' d='M10 15.27L16.18 19l-1.64-7.03L20 7.24l-7.19-.61L10 0 7.19 6.63 0 7.24l5.46 4.73L3.82 19z' fill='%231a1b1d'/%3E%3C/svg%3E");
    background-repeat: repeat-x;
    background-position: left center;
    background-size: 24px;
  }

//product grid

.banner--category .banner__image-wrapper {
  margin:0 0 16px;
}
//product view

.button.product-view__add-to-cart.button--add-to.action.tocart {
    width:100%;

}

.button.product-view__add-to-quote.button--add-to.action.tocart {
    width:100%;

}

.button:before {
  background-color: #8116d4;
}

.product-grid-item__actions {
width: 100%;
justify-content: center;
display: flex;
}

.product-grid-item__primary-action {
    margin: 0 0 0 0;

}

.product-grid-item__name {
flex-grow: 1;
    justify-content: space-evenly;
}

.product-grid-item__actions
{
  min-height: 48px;
  min-width: 48px;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
}

.product-grid-item__stock-status--out-of-stock {
  font-weight:bolder;
}


.product-list__add-to-cart, .product-list__update-cart {
    flex-grow: 0;
    background-color: #8116D4;
    /* align-items: center; */
    /* width: 50%; */
    justify-content: center;
}

.product-list__add-to-cart:hover , .product-list__update-cart:hover {
    background-color: #8116D4;
}

.product-list__add-to-cart:hover:before , .product-list__update-cart:hover:before {
    background-color: #8116D4;
}

@media (min-width: 1200px) {
.product-grid-item__details {
  flex-grow: 1;
  justify-content: space-evenly;
}

.product-grid-item__link {
  flex: unset;
}

.product-grid-item:not(:root:root) {
  display: flex;
}
}

@media all and (max-width: 768px) {
.add-to-cart__action .product-view__add-to-cart, .add-to-cart__action .product-view__update-cart{
  display:block;
}
.item.product.product-item .product-item-info
{
  overflow:hidden;
}
}

.widget.block.block-static-block {
    margin: 20px;
}

.products.list.items.product-items {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}

.product-support {
    clear: both;
}

.product-support .items {
  padding: 0;
  margin: 0;
  list-style: none;

  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  min-height: 44px;
  align-items: center;
  justify-content: center;
}

.product-support .items > div {
    flex-grow: 1;
margin: auto;
padding-top: 10px;
padding-bottom: 10px;
text-align:center
}


.item.product.product-item:nth-child(5n+1) {
    margin-left: 0;
}

.item.product.product-item {
    margin-left: 2%;
    width: calc((100% - 8%)/5);
}

.product.details.product-item-details {
     padding-top: 10px;
     padding-bottom: 10px;
 }

.brief-info__icon__wrapper {
 display:inline-block;
 vertical-align: middle;
 text-align: center;
}

.brief-info__text__wrapper {
    display:inline-block;
    text-align: left;
    vertical-align: top;
}


.brief-info__icon-wrapper {
    width:36px;
    height:36px;
}

.brief-info__icon-wrapper svg {
width:36px;
height:36px;
}

.brief-info__icon__wrapper svg {
width:36px;
height:36px;
}

.brief-info__icon-wrapper {
    /*vertical-align: -moz-middle-with-baseline;*/
}

.brief-info__text {
    display: inline flow-root list-item;
    text-align: left;
    line-height: 1;
}

.brief-info__text .brief-info__title {

    color: gray-darker;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    line-height: 1;
}

.brief-info__text .brief-info__subtitle {
    line-height: 1;
}

.product-support .items .brief-info__text:first-child:nth-last-child(n + 4),
.product-support .items .brief-info__text:first-child:nth-last-child(n + 4) ~ .product-support .items .brief-info__text {
    text-align: center;
}

.product-labels{
position: absolute;
top: 10px;
right: 0;
}

.product-label.sale-label{

  background: #FFF;
  border: solid 2px black;
  padding: 2px;
  color: black;
  font-weight: bold;
  margin-bottom: 2px;
}

.product-label.pre-order-label{

  background: #FFF;
  border: solid 2px #8116D4;
  padding: 2px;
  color: #8116D4;
  font-weight: bold;
  margin-bottom: 2px;
}

.product-label.sale-view-label{

  background: #FFF;
  border: solid 2px black;
  padding: 2px;
  color: black;
  font-weight: bold;
  margin-bottom: 2px;
  margin-left: 10px;
  display:inline;
}

.product-view__price
{
  display:inline-block;
}

.product-label.shipping-label
{

  background: #FFF;
  border: solid 2px black;
  padding: 2px;
  color: black;
  font-weight: bold;
  margin-bottom: 2px;
  clear: both;
}

.product-label.coupon-label
{
  background: #FFF;
  border: solid 2px #03CEA4;
  padding: 2px;
  color: #03CEA4;
  font-weight: bold;
  margin-bottom: 2px;
  text-align: center;
  clear:both;
}

.product-grid-item  .price-box.price-final_price {
  margin-bottom: 10px;
}

.product-grid-item .product-support {
  margin: 10px 0 20px 0;
}

.product-grid-item .add-to-wishlist {
    position: absolute;
    top: 0;
    left: 0;

    background: transparent;
}

.product-list__call-for-info {
  background-color: #eac435;
  order:1;
  flex-grow: 1;
  padding: 0 16px;
}

.product-list__call-for-info::before
{
  background-color: #eac435;
}

.product-view__call-for-info{
  background-color: #eac435;
  order:1;
  flex-grow: 1;
  height: 56px;
  padding: 0 16px;
}

.product-view__call-for-info::before
{
  background-color: #eac435;
}

.button--call-for-info:after {
  color:white;
}

.product-view__stock:hover{
  color: #8116D4;
}

.product-view__pre-order{
  background-color: #eac435;
  order:1;
  flex-grow: 1;
  height: 30px;
  min-height: 30px;
  padding: 0 16px;
}

.product-view__pre-order::before
{
  background-color: #8116D4;
}

.button--pre-order:after {
    color:#8116D4;
    background-color: #8116D4;

}

.product-view__main-status .brief-info__title{
  color: #8116D4;
  text-transform: capitalize;
}

.product-view__main-status .paragraph,.product-view__main-status p,.product-view__main-status .information__text{
font-size: 13px;
}

.list-openbox {
  width: 100%;
}

.list-openbox a{
  display:inline !important;
  color: #07A0C3;
  font-weight: 700;
}

.product-view .list-openbox{
    padding-bottom: 16px;
}

#block-discount-heading
{
margin: 0 8px 0 0;
font-size: 18px;
font-weight: 400;
color: #1a1b1d;
text-transform: uppercase;
}

.am-widget-center {
    margin: 0 auto;
}


input.am-input.input-text {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: 1px solid #6e7577;
    border-radius: 0;
    line-height: normal;
    transition: all 0.3s ease-in-out;
}

button.am-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 48px;
    min-width: 48px;
    padding: 0 32px;
    background-color: #1a1b1d;
    border: none;
    border-radius: 0;
    outline: none;
    font-family: "Work Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    transform: perspective(1px) translateZ(0);
    transition: all 0.5s ease-in-out;
}

button.am-button:after {
  opacity:1;
}

.cms-page__content {
  max-width: 88%;
  margin: 0 auto; }
  @media all and (min-width: 768px) {
    .cms-page__content {
      max-width: 88%; } }
  @media all and (min-width: 1024px) {
    .cms-page__content {
      max-width: 88%; } }
  @media all and (min-width: 1200px) {
    .cms-page__content {
      max-width: 88%; } }
  .cms-contact .cms-page__content,
  .cms-contact-us .cms-page__content {
    max-width: 100%; }


.sidebar.sidebar-main
{
  content: none;
  position: absolute;
}

.page-layout-2columns-left .sidebar, .page-layout-2columns-right .sidebar {
    padding-right: 2%;
}

.amfaq-search {
position: relative;
}

.amfaq-search input.am-input.input-text {
  float:left;
}

.amfaq-search .am-button {
  float:left;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    width: 30px;
    height: 38px;
    border: 0;
    box-shadow: none;
}

.am-widget-categories-1, .am-widget-categories-2, .am-widget-categories-3 {
    clear: both;
    display: flex;
    display: -webkit-flex;
    -webkit-flex-flow: row wrap;
    -moz-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    padding-top: 16px;
}

.am-widget-category {
  width: 33.33%;
}

.amfaq_categories {
  clear: both;
  padding-top:16px;
}

.faq-category-title{
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  font-variant: small-caps;
}

.am-links a{
  color: #07A0C3;
  font-weight: 500;
}

.coupon input.input-text {
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: 1px solid #6e7577;
    border-radius: 0;
    line-height: normal;
    transition: all 0.3s ease-in-out;
}

.coupon button.action {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 48px;
    min-width: 48px;
    padding: 0 32px;
    background-color: #1a1b1d;
    border: none;
    border-radius: 0;
    outline: none;
    font-family: "Work Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
    transform: perspective(1px) translateZ(0);
    transition: all 0.5s ease-in-out;
}

.braintree-googlepay-button.long {
    min-width: 150px;
    width: 100%;
}

.order-summary tr.totals-tax
{
display: flex;
justify-content: space-between;
align-items: center;
min-height: 48px;
}

.order-summary tr.grand.totals
{
  display: flex;
justify-content: space-between;
align-items: center;
min-height: 48px;
}

.footer__bottom-bar {
border-top: 10px solid #8116d4;
}

.marketing-bar__text {
padding: 0px 0px;
}

.marketing-bar{
min-height: unset;
}

.header__logo{
width: 256px;
}


.file-items-no-category {
    margin: 67px 0 0 35px;
}

.category-container {
    margin: 30px 0 0 35px;
}

.categories-block {
    display: flex;
    flex-wrap: wrap;
    margin: -30px 0 0 -35px;
    width: calc(100% + 35px);
    align-items: flex-start;
}

.category-container .title {
    border-radius: 3px;
    display: block;
    font-weight: 600;
    padding: 5px 37px 5px 10px;
    position: relative;
    width: 215px;
    display: flex;
    align-items: center;
}

.am-attachments .am-fileline .am-fileicon {
    margin-right: 10px;
    max-height: 18px;
    vertical-align: middle;
    display: inline;
}

.minicart-content__middle {
min-heigth: 200px;
}

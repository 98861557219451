// add to cart button
$product-view__add-to-cart-background-color                  : #8116D4;

// add to quote button
$product-view__add-to-quote                                  : none !default;
$product-view__order                                        : 1 !default;
$product-view__flex-grow                                    : 1 !default;
$product-view__add-to-quote-height                           : 56px !default;
$product-view__add-to-quote-padding                          : 0 $spacer--medium !default;
$product-view__add-to-quote\@buttons-mq-display              : inline-flex !default;
$product-view__add-to-quote-background-color                  : #03CEA4;

// sticky EE version
$product-view__add-to-quote-ee-wrapper-padding               : $spacer--small $spacer $spacer !default;
$product-view__add-to-quote-ee-wrapper-padding\@medium       : $spacer--semi-medium $spacer !default;
$product-view__add-to-quote-ee-wrapper-padding\@large        : $spacer--semi-medium $spacer--semi-large !default;

$product-view__add-to-quote-ee-action-bottom                 : $spacer !default;
$product-view__add-to-quote-ee-action-right                  : $spacer !default;

$product-view__add-to-quote-ee-price-box-padding             : $spacer--small 0 0 !default;
$product-view__add-to-quote-ee-price-box-padding\@large      : $spacer--extra-small 0 0 !default;
